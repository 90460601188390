#title {
  grid-area: title;
  font-size: 1em;
  a {
    margin: 30px;
  }
}

#credits {
  grid-area: credits;
  font-size: 0.6em;

  h3 {
    margin-bottom: 2px;
  }
  ul {
    max-height: 520px;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    margin-top: 4px;
    text-align: left;
    li.left {
      margin-top: 5px;
      width: 20vw;
      cursor: pointer;
    }
  }
  @media (max-width: 720px) {
    ul {
      padding-left: 4px;
      width: 20vw;
      li {
        width: 18vw;
      }
    }
  }
}

#bio {
  grid-area: bio;
  max-width: 95%;
  line-height: 1.25rem;
  word-spacing: 3px;
  letter-spacing: 0.75px;
  font-size: 0.6em;
  padding-top: 20px;
  section {
    margin: auto;
    width: 65vw;
    text-align: left;
  }
}

#playbill {
  grid-area: playbill;
  display: flex;
  max-width: 95%;
  justify-content: center;
  @media (max-width: 720px) {
    width: 50vw;
  }
  img {
    max-height: 400px;
    max-width: 90%;
    height: auto;
  }
}

#frameworks {
  display: block;
  grid-area: frameworks;
  text-align: left;
  overflow-x: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-right: 15px;
    width: 80%;
    li {
      list-style: none;
      width: fit-content;
      padding: 3px;
    }
  }
  @media (min-width: 720px) {
    margin: 0px;
  }
}
#aboutgrid {
  width: 97vw;

  @media (orientation: landscape) {
    display: grid;
    grid-template-areas:
      "credits title frameworks"
      "credits playbill frameworks"
      "bio bio bio";

    grid-template-columns: 25vw 50vw 25vw;
    grid-template-rows: auto 1fr auto;
    #frameworks {
      overflow-y: scroll;
      h3 {
        width: 95%;
      }
      div {
        display: flex;
        padding: 5px 20px;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
          margin: 5px;
        }
      }
    }
  }
  #title p {
    margin: 0px auto;
    padding: 0px;
    text-align: center;
  }
  h3 {
    width: 95%;
    text-align: center;
    padding-bottom: 0px;
    margin-bottom: 1px;
  }
  @media (orientation: portrait), (max-width: 700px) and (max-height: 400px) {
    display: grid;
    grid-template-areas:
      "title title"
      "frameworks frameworks"
      "bio bio"
      "credits playbill";

    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    #frameworks {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 30px;

      li {
        margin: 4px 8px;
      }
      div {
        justify-content: space-between;
      }
    }
    #credits {
      grid-row: span 1;
      grid-column: span 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      ul {
        display: block;
        height: 280px;
        width: 90%;
        line-height: 1px;
        li.left {
          height: 20px;
          white-space: nowrap;
          font-size: 0.75rem;
          padding: 5px;
          width: auto;
          margin: 12px;
        }
      }
    }
    #playbill {
      grid-row: span 1;
      grid-column: span 1;

      margin: 30px auto;
      height: 300px;
    }
  }
}
