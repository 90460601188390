.App {
  text-align: center;
}

.icon {
  width: 70px;
  height: 75px;
  fill: red;
  padding: 7px;
  stroke: white;
  stroke-width: 0.4px;
}

.icon:hover {
  fill: rgb(85, 154, 159);
  transition-duration: 0.8s;
}

.links:hover {
  box-shadow: none;
  transition-delay: 10s;
}

.code-icon {
  height: 140px;
  width: 20vw;
  min-width: 150px;
  padding-top: 1vh;

  @media (max-width: 640px), (max-height: 500px) {
    justify-content: center;

    section {
      padding-top: 0;
    }
  }
}

.home-enter,
.projects-enter,
.resume-enter {
  opacity: 0;
}

.home-enter-active,
.projects-enter-active,
.resume-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.home-exit,
.projects-exit,
.resume-exit {
  opacity: 1;
}

.home-exit-active,
.projects-exit-active,
.resume-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

.scale-in-center {
  -webkit-animation: scale-in-center 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.projects-enter {
  -webkit-animation: projects-enter 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: projects-enter 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes projects-enter {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes projects-enter {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.about-enter {
  -webkit-animation: about-enter 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: about-enter 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes about-enter {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes about-enter {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#links {
  grid-area: links;

  a {
    margin: 30px;
  }
}

#nav {
  grid-area: nav;

  h3 {
    margin-bottom: 2px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    li.left {
      width: 90%;
      margin-top: 4px 10px;
    }
  }
}

#desc {
  grid-area: desc;
  width: 95vw;
  margin: 0 5vw;
  font-size: 0.8em;
  padding-top: 20px;
}

#video {
  grid-area: video;
  display: flex;
  max-width: 100%;
  justify-content: center;
}

#languages {
  display: block;
  grid-area: languages;
  text-align: left;
  padding-top: 1.8rem;
  li {
    list-style: none;
    width: inherit;
    padding: 3px;
  }
}
#grid {
  width: 97vw;

  @media (orientation: landscape) {
    display: grid;

    grid-template-areas:
      "nav links languages"
      "nav video languages"
      "desc desc desc";

    grid-template-columns: 20vw 60vw 20vw;
    grid-template-rows: auto 2fr auto;

    #languages {
      overflow-y: scroll;
    }
  }
  @media (orientation: portrait), (max-width: 680px) and (max-height: 400px) {
    display: grid;
    grid-template-areas:
      "links links"
      "video video"
      "desc desc"
      "languages languages"
      "nav nav";

    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto;
    #languages {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 5px 90px;
      li {
        margin: 4px 10px;
      }
    }
    div {
      margin: 0 auto;
      ul {
        display: flex;
        width: 90vw;
        // width: fit-conent;
        margin: auto;
        li.left {
          display: block;
          margin: auto;
          width: min-content;
        }
      }
    }
    #video {
      width: 90vw;
    }
    #nav {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.map {
  outline: none;
  justify-self: center;
  margin: 0 auto;
  height: 80vh;
  width: 90vw;
  border: 3px solid black;
  border-radius: 15px;
  box-shadow: inset 1px 1px 10px black;
}

html body ::-webkit-scrollbar {
  width: 2px;
  background: rgba(139, 139, 161, 0.4);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}
::-webkit-scrollbar-button:end {
  height: 30px;
  width: 30%;
}

::-webkit-scrollbar-button:start {
  height: 30px;
  width: 30%;
}

html body ::-webkit-scrollbar-track {
  border-radius: 8px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}

html body ::-webkit-scrollbar-thumb:hover {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}
html body ::-webkit-scrollbar-button {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}

.food-enter {
  -webkit-animation: food-enter 0.9s both;
  animation: food-enter 0.9s both;
}

@-webkit-keyframes food-enter {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes food-enter {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.broadway {
  color: black;
  font-size: 3rem;
  font-weight: 900;
}
.developer {
  color: black;
  font-size: 3rem;
  font-weight: 900;
}


#mapid{
  text-align: center;
  display: flex;
  margin-top:50px;
  justify-content: center;
  
}

.leaflet-container{
  border: 3px solid rgb(148, 140, 140);
  box-shadow: 2px 2px 4px 4px lightgrey;
  border-radius: 20px;
  height:70vh;
  width: 78vw;
}

.food-desc{
  font-size: 0.9rem;
  padding: 10px 20vw;
}