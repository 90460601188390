body {
  margin: 0;
  font-family: "Raleway", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5em;
}

code {
  font-family: monospace, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New";
}
a {
  text-decoration: none;
  color: inherit;
}

.left {
  position: relative;
}

.left:before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: rgb(85, 154, 159);
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.left:hover:before {
  visibility: visible;
  width: 100%;
}
