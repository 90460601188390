.svg-icon {
  @media (max-width: 600px) {
    max-width: 50%;
  }
  padding-top: 3px;
  height: 30vh;
  margin: 0 auto;
}

.resume-icon {
  @media (max-width: 600px) {
    padding-left: 20px;
    max-width: 50%;
  }
}

main div.main-nav {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 5px 5px 20px black;
  height: 40vh;
  min-height: 220px;
  width: 60vw;
  min-width: 250px;
  border: 2px solid #161515;
  border-radius: 15px;
  margin: 40px;
  transition-duration: 2s;

  @media (max-width: 650px) {
    height: 65vw;
  }
  @media (orientation: landscape) and (max-height: 400px),
    (orientation: portrait) and (max-width: 500px) {
    width: 69vw;
    div {
      font-size: 0.8em;
    }
  }
  :hover {
    box-shadow: 3px 3px 10px white;
    transition-duration: 2s;
  }
}
